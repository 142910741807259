import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homepage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children: [

    ]
  },

  // 首页
  {
    path: '/homepage',
    component: () => import('../views/homepage/index.vue')
  },
  // 产品线
  {
    path: '/productLine',
    component: () => import('../views/productLine/index.vue')
  },
  // 应用软件
  {
    path: '/application',
    component: () => import('../views/application/index.vue')
  },
  // 行业方案
  {
    path: '/industry',
    component: () => import('../views/industry/index.vue')
  },
  // 联系我们
  {
    path: '/my',
    component: () => import('../views/my/index.vue')
  },

  // 自定义404页面
  {
    path: '*',
    component: () => import('../views/NoFoundPage.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
